<template>
  <v-container fluid class="invite-container px-0 px-sm-auto">
    <CategoryTitle :category="category" :add-container="false" />

    <!-- CODICE SCONTO -->
    <v-row
      no-gutters
      class="personal-code pt-4 pb-11 default--text"
      justify="center"
      align="center"
    >
      <v-col cols="12" class="text-center">
        <h3 class="code-title">
          {{ $t("invite.code.title") }}
        </h3>
        <v-row
          no-gutters
          class="text-h3 font-weight-bold code-box rounded-md mt-4 mx-auto"
          align="center"
        >
          <v-col cols="12" class="code-value text-h2"
            ><span v-if="code">{{ personalCode.tellFriend.code }}</span></v-col
          >
        </v-row>
        <h4 class="font-weight-regular">
          {{ $t("invite.code.actions") }}
        </h4>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="image-button email mr-3"
              @click="shareOnMail"
            ></v-btn
          ></template>
          {{ $t("invite.code.email") }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="image-button whatsapp"
              @click="shareOnWA"
            ></v-btn>
          </template>
          {{ $t("invite.code.whatsapp") }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="image-button copy ml-3"
              @click="copyCode"
            ></v-btn>
          </template>
          {{ $t("invite.code.copy") }}
        </v-tooltip>
      </v-col>
    </v-row>

    <v-divider />

    <!-- BUONI SCONTO -->
    <v-row no-gutters class="gift-certificates pt-7" v-if="giftCertificates">
      <v-col cols="12" class="text-center">
        <h3 class="gifts-title">
          {{ $t("invite.gifts.title") }}
        </h3>
        <v-row
          no-gutters
          class="count-box text-h3 font-weight-bold rounded-md grey lighten-1 py-6 mt-4 mx-auto default--text"
          align="center"
        >
          <v-col cols="12">
            <div class="count font-weight-bold">
              {{ giftCertificates.length }}
            </div>
            <div class="text-h4 font-weight-regular">
              {{ $tc("invite.gifts.friends", giftCertificates.length) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters class="list my-11">
      <v-list dense class="w-100">
        <v-list-item v-for="(item, idx) in giftCertificates" :key="idx">
          <v-row justify="space-between" class="px-4">
            <div>{{ `${item.name} (${$n(item.amount, "currency")})` }}</div>
            <div>{{ formatEndDate(item.endDate) }}</div>
          </v-row>
        </v-list-item>
      </v-list>
    </v-row>

    <v-divider />

    <ebsn-meta :target="category" path="category_info.FOOTER_TEXT" tag="div" />
  </v-container>
</template>
<style lang="scss">
.invite-container {
  .invite-card {
    .v-image {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .desc {
      div {
        line-height: 22px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          line-height: 18px;
        }
      }
      .ul-classes {
        margin: 20px 0px;
        .li-classes {
          list-style: disc !important;
          margin-left: 25px;
        }
      }
    }
  }
  .personal-code {
    .code-box {
      border: 2px solid var(--v-primary-lighten1);
      max-width: 330px;
      min-height: 53px;
      .v-image {
        cursor: pointer;
      }
    }
  }
  .gift-certificates {
    .count-box {
      border: 1px solid $border-color;
      max-width: 330px;
      .count {
        font-size: 41px;
      }
    }
  }

  .list {
    .v-list-item {
      margin-bottom: 5px;
      border: 2px solid var(--v-grey-lighten1);
      border-radius: 6px;
    }
    .v-list-item:hover {
      background: var(--v-grey-lighten1);
    }
  }

  .instructions {
    h2 {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }
    .box-ctn {
      padding: 10px;
      .friend-info-box {
        background-color: var(--v-grey-lighten1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 400px;
        padding: 30px;
        border-radius: 0;
        border-radius: 6px;
        .step {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #044788;
          color: #ffffff;
          border-radius: 50%;
          font-size: 24px;
          font-weight: 700;
          padding: 10px;
          height: 60px;
          width: 60px;
          min-height: 60px;
          min-width: 60px;
          @media #{map-get($display-breakpoints, 'md-and-down')} {
            height: 50px;
            width: 50px;
            min-height: 50px;
            min-width: 50px;
          }
        }
        h3 {
          width: 100%;
          text-align: center;
          margin-bottom: 40px;
          font-size: 20px;
        }
        .body {
          flex-grow: 1;
          margin-top: 20px;
          display: flex;
          flex-direction: column;
        }
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          height: 350px;
        }
      }
    }
    .instruction-box {
      border: 1px solid var(--v-grey-base);
      border-radius: $border-radius-root;
      padding: 24px;
      color: $primary;
      min-height: 240px;
      height: 100%;
      background-color: var(--v-primary-lighten2);
      @media #{map-get($display-breakpoints, 'sm-only')} {
        min-height: 330px;
      }
      .text-h4 {
        line-height: 28px;
        min-height: 75px;
      }
    }
  }

  .uses-clause {
    .li-classes {
      list-style: disc !important;
      margin-left: 25px;
    }
  }
  .image-button {
    background-size: contain;
    background-position: center;
  }
  .image-button.whatsapp {
    background-image: url("/img_layout/whatsapp.png");
    background-size: 90%;
  }
  .image-button.email {
    background-image: url("/img_layout/email.png");
    background-size: 75%;
  }
  .image-button.copy {
    background-image: url("/img_layout/copy.png");
    background-size: 70%;
  }
}
</style>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import categoryMixins from "~/mixins/category";
import { mapGetters } from "vuex";

import InviteService from "~/service/inviteService";

import get from "lodash/get";

export default {
  name: "Invite",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      personalCode: null,
      instructions: null,
      swiperOption: {
        spaceBetween: 10,
        autoplay: false,
        watchOverflow: true,
        pagination: {
          el: "#widget-slider-pagination-invite-steps",
          clickable: true
        },
        navigation: {
          prevEl: "#widget-slider-prev-invite-steps",
          nextEl: "#widget-slider-next-invite-steps"
        },
        breakpoints: {
          600: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 3
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    catDescr() {
      return get(this.category, "metaData.category_info.DESCRIPTION");
    },
    code() {
      return (
        this.personalCode &&
        this.personalCode.tellFriend &&
        this.personalCode.tellFriend.code
      );
    },
    giftCertificates() {
      // let array = [
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "01/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "05/05/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "07/06/2022"
      //   },
      //   {
      //     name: "Buono Sconto 5€",
      //     endDate: "15/09/2022"
      //   }
      // ];
      // if (this.personalCode && this.personalCode.userGiftCertificates) {
      //   array.concat(
      //     this.personalCode && this.personalCode.userGiftCertificates
      //   );
      // }
      // return array;
      if (this.personalCode && this.personalCode.userGiftCertificates) {
        return this.personalCode && this.personalCode.userGiftCertificates;
      }
      return [];
    },
    usesClauseList() {
      let list = this.geti18nObject("invite.usesClause.lists");
      return list;
    },
    cardList() {
      let list = this.geti18nObject("invite.card.lists");
      return list;
    }
  },
  methods: {
    copyCode() {
      if (this.personalCode.tellFriend && this.personalCode.tellFriend.code) {
        navigator.clipboard.writeText(this.personalCode.tellFriend.code);
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("invite.code.copied")
        });
      }
    },
    formatEndDate(date) {
      return this.$dayjs(date).format("D/MM/YYYY");
    },
    shareOnMail() {
      let subject = this.user.firstName + " ti ha inviato un codice sconto!";
      let body =
        this.user.firstName +
        " ti ha inviato un codice sconto che puoi utilizzare sulla tua prima spesa online su cooptrentino.it, inserisci il codice " +
        (this.code ? this.code : "") +
        ".";
      window.open("mailto:?subject=" + subject + "&body=" + body, "_self");
    },
    shareOnWA() {
      let text =
        this.user.firstName +
        " ti ha inviato un codice sconto che puoi utilizzare sulla tua prima spesa online su cooptrentino.it, inserisci il codice " +
        (this.code ? this.code : "") +
        ".";
      window.open("https://api.whatsapp.com/send/?phone&text=" + text, "_self");
    }
  },
  async created() {
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
    }
  }
};
</script>
